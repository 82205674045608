import {
  DirectToDoctorPrimarySymptom,
  Status,
  getDirectToDoctorPrimarySymptoms,
} from "../services/core-api-adapter";
import { useQuery } from "@tanstack/react-query";

interface UseGetDirectToDoctorPrimarySymptomsProps {
  status?: Status;
}

export default function useGetDirectToDoctorPrimarySymptoms({
  status = Status.ACTIVE,
}: UseGetDirectToDoctorPrimarySymptomsProps = {}) {
  const controller = new AbortController();

  const { data, isPending, isLoading, isError, error } = useQuery({
    queryKey: ["getDirectToDoctorPrimarySymptomsData"],
    queryFn: async () => {
      const response = await getDirectToDoctorPrimarySymptoms(status, {
        signal: controller.signal,
      });
      return response as DirectToDoctorPrimarySymptom[];
    },
  });

  return {
    primarySymptoms: data || [],
    primarySymptomsFetchError: isError || error,
    isPrimarySymptomsLoading: isLoading || isPending,
  };
}
