import { useQuery } from "@tanstack/react-query";
import {
  getMarketingChannels,
  MarketingChannel,
  Status,
} from "../services/core-api-adapter";

interface UseGetMarketingChannelsProps {
  status: Status;
}

export default function useGetMarketingChannels({
  status,
}: UseGetMarketingChannelsProps) {
  const controller = new AbortController();

  const { data, isLoading, isPending, isError, error } = useQuery({
    queryKey: ["getMarketingChannelData"],
    queryFn: async () => {
      const responseData = await getMarketingChannels(status, {
        signal: controller.signal,
      });

      return responseData as MarketingChannel[];
    },
  });

  return {
    marketingChannels: data,
    marketingChannelsFetchError: error || isError,
    isMarketingChannelsLoading: isLoading || isPending,
  };
}
