import { useQuery } from "@tanstack/react-query";
import { getMarketingChannelsProvinces } from "../services/core-api-adapter";

export default function useGetMarketingChannelsProvinces(id: string) {
  const controller = new AbortController();

  const { data, isLoading, isPending, isError, error } = useQuery({
    queryKey: ["getMarketingChannelsProvincesData", id],
    queryFn: async () => {
      const responseData = await getMarketingChannelsProvinces(id, {
        signal: controller.signal,
      });

      return responseData as string[];
    },
    enabled: !!id,
  });

  return {
    marketingChannelsProvinces: data,
    marketingChannelsProvincesFetchError: error || isError,
    isMarketingChannelsProvincesLoading: isLoading || isPending,
  };
}
